body {
  background-image: url('./background.png');
  background-repeat: repeat;
  /* font-family: 'Ariel', sans-serif; */
}

/* svg.bi {
  display: inline-block;
  vertical-align: -0.15em;
} */

.site-header a.logo {
  color: #19f;
}

.site-header a:hover {
  color: #19f;
}

.site-header {
  color: #fff;
}

footer .text-muted, footer a.link-dark {
  color: #102d3d!important;
}

footer a.text-muted:hover, footer a.link-dark:hover {
  color: #46a!important;
}

/* .custom-tooltip {
  --bs-tooltip-bg: var(--bs-primary);
} */

/* .site-header a.logo {
  color: #19f;
  transition: color .15s ease-in-out;
  background-color: #fff;
  border-radius: 21px;
  height: 48px;
  padding: 0px;
  margin: 0px;
} */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


/* .container {
  max-width: 960px;
} */

/*
 * Custom translucent site header
 */

.bg-darka, .sticky-top {
/* .site-header { */
  background-color: rgba(33, 37, 41, 0.85); /*#102d3d*/
  /*background-color: hsla(201, 58%, 15%, 0.85); !*#102d3d*!*/
  /* background-color: rgba(0, 0, 0, .85); */
  -webkit-backdrop-filter: saturate(80%) blur(3px);
  backdrop-filter: saturate(80%) blur(3px);
}

.sticky-footer {
  position: fixed;
  bottom: 0;
  right: 0;
}

/* .site-header a {
  color: #fff;
  transition: color .15s ease-in-out;
}

.site-header a:hover {
  color: #8e8e8e;
  text-decoration: none;
} */

/*
 * Dummy devices (replace them with your own or something else entirely!)
 */

.product-device {
  position: absolute;
  right: 10%;
  bottom: -30%;
  width: 300px;
  height: 540px;
  background-color: #333;
  border-radius: 21px;
  transform: rotate(30deg);
}

.product-device::before {
  position: absolute;
  top: 10%;
  right: 10px;
  bottom: 10%;
  left: 10px;
  content: "";
  background-color: rgba(255, 255, 255, .1);
  border-radius: 5px;
}

.product-device-2 {
  top: -25%;
  right: auto;
  bottom: 0;
  left: 5%;
  background-color: #e5e5e5;
}


/*
 * Extra utilities
 */

.flex-equal > * {
  flex: 1;
}
@media (min-width: 768px) {
  .flex-md-equal > * {
    flex: 1;
  }
}
